import {nl2br} from '@ivosabev/helpers/nl2br';
import {type NotificationData, notifications} from '@mantine/notifications';
import hash from 'object-hash';
import {useCallback} from 'react';
import {Icon} from '#/app/components/admin/Icon.tsx';

export const useToastAdvanced = () => {
  const show = useCallback((options: NotificationData) => {
    const variant = options.variant || 'default';
    const description = nl2br(options.description || '');
    const title = options.title || '';
    const position = options.position || 'bottom-left';
    const isClosable = options.isClosable ?? true;
    const id = hash({description, title});

    let icon: React.ReactNode | undefined = undefined;
    let color: string | undefined = undefined;
    let autoClose: number | undefined = undefined;
    if (variant === 'error') {
      color = 'red';
      icon = <Icon icon="far-circle-exclamation" />;
      autoClose = options.duration ?? 5000;
    } else if (variant === 'ok' || variant === 'success') {
      color = 'green';
      icon = <Icon icon="far-circle-check" />;
      autoClose = options.duration ?? 5000;
    } else {
      autoClose = options.duration ?? 5000;
    }

    notifications.show({
      ...options,
      autoClose,
      className: variant,
      color,
      icon,
      id,
      message: description,
      position,
      title,
      withBorder: true,
      withCloseButton: isClosable,
    });

    return id;
  }, []);

  return {...notifications, show} as typeof notifications;
};
